import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "Whats-new",
      "style": {
        "position": "relative"
      }
    }}>{`What's new`}</h3>
    <p>{`February 13, 2025 changes:`}</p>
    <ul>
      <li parentName="ul">{`Updated geographical references to reflect the new name Gulf of America for the area formerly called Gulf of Mexico, following Executive Order 14172.`}</li>
    </ul>
    <p>{`Review our `}<a parentName="p" {...{
        "href": "https://github.com/ONRR/nrrd/releases"
      }}>{`full release details`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      