import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "terms": [{
    "name": "8(g)",
    "definition": "The 8(g) zone is the offshore region within three miles of a state shoreline. A portion (27%) of revenue from production in the 8(g) zone is distributed to the respective states that border the zone. The 8(g) zone is named for the section within the Outer Continental Shelf Lands Act that designates it.",
    "tags": ["8(g) offshore"],
    "categories": ["Disbursement"]
  }, {
    "name": "Abandoned Mine Land (AML) Fee",
    "definition": "A fee for current day coal production that funds reclamation of mines abandoned before 1977.",
    "tags": ["Abandoned Mine LandFees", "AML"],
    "categories": ["Revenue"]
  }, {
    "name": "Accounting Year",
    "definition": "This data is based on transactions that were reported to and accepted into ONRR’s financial system in a given a year. Since companies are allowed to adjust and correct data up to seven years after a transaction takes place, accounting year data can include corrections for sales that took place in previous years. This data is most useful when analyzing dollars ONRR collected and disbursed in a given year.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Acquired Lands",
    "definition": "Acquired lands are public lands that were obtained by the federal government through purchase, condemnation, gift, or exchange.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Acquisition Fee",
    "definition": "A fee for securing an uncompetitive lease in place of a bonus.",
    "categories": ["Revenue"]
  }, {
    "name": "Annual Fee",
    "definition": "A yearly maintenance fee for maintaining a claim.",
    "categories": ["Revenue"]
  }, {
    "name": "APD",
    "definition": "Application for Permit to Drill.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Appropriation",
    "definition": "Amount of money set aside for a fund or recipient during the federal budget process. Congress designates a specific amount to be distributed to the fund or recipient.",
    "tags": ["appropriate", "appropriated", "appropriations"],
    "categories": ["Disbursement"]
  }, {
    "name": "Authorization",
    "definition": "An act of Congress to obligate funding for a program or agency. An authorization may be effective for one year, a fixed number of years, or an indefinite period. An authorization may be for a definite amount of money or for 'such sums as may be necessary.' The formal federal spending process consists of two sequential steps: authorization and then appropriation.",
    "tags": ["authorized", "authorizes"],
    "categories": ["Disbursement"]
  }, {
    "name": "Barrel",
    "definition": "In the U.S., an oil barrel is defined as 42 US gallons and abbreviated as bbl.",
    "categories": ["Units and Measurements"]
  }, {
    "name": "Bbl",
    "definition": "Abbreviation for a unit of measurement of oil, one bbl, or oil barrel. Defined as 42 US gallons.",
    "tags": ["(bbl)"],
    "categories": ["Units and Measurements"]
  }, {
    "name": "Biomass",
    "definition": "Organic nonfossil matter used as fuel. Sources of biomass include wood, wood waste products, biofuel, and many plant-based materials.",
    "categories": ["Units and Measurements"]
  }, {
    "name": "BLM",
    "definition": "The Bureau of Land Management (BLM) is part of the U.S. Department of the Interior, and manages exploration, development, and production of natural resources on federal lands.",
    "categories": ["Offices and Regulations"]
  }, {
    "name": "BOEM",
    "definition": "The Bureau of Ocean Energy Management (BOEM) is part of the U.S. Department of the Interior and is responsible for managing the development of energy and mineral resources on the U.S. Outer Continental Shelf.",
    "categories": ["Offices and Regulations"]
  }, {
    "name": "Bonus",
    "definition": "The amount the highest bidder paid for a natural resource lease.",
    "tags": ["bonuses"],
    "categories": ["Revenue"]
  }, {
    "name": "BSEE",
    "definition": "The Bureau of Safety and Environmental Enforcement (BSEE) is part of the U.S. Department of the Interior, and is charged with promoting safety, protecting the environment, and conserving resources offshore through regulatory oversight and enforcement.",
    "categories": ["Offices and Regulations"]
  }, {
    "name": "Calendar Year (CY)",
    "definition": "The calendar year runs from January 1 through December 31. The two annual time periods for reporting data are calendar year and fiscal year.",
    "categories": ["Production and Commodities", "Federal Sales"]
  }, {
    "name": "Civil Penalties",
    "definition": "ONRR issues civil penalties when companies fail to comply with, or knowingly or willfully violate, regulations or laws.",
    "categories": ["Offices and Regulations"]
  }, {
    "name": "Claim-Staking Fee",
    "definition": "A fee that covers the government’s administrative costs in the claim-staking process for mining on federal lands.",
    "tags": ["claim-staking"],
    "categories": ["Revenue"]
  }, {
    "name": "Coastal Political Subdivision",
    "definition": "A state's political subdivision, such as a county, parish, borough, or city. The political subdivision must be within the coastal zone as defined in the Coastal Zone Management Act of 1972.",
    "tags": ["coastal political subdivisions"],
    "categories": ["Land and Ownership"]
  }, {
    "name": "Crude Oil",
    "definition": "Oil that is not treated or refined.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Cyds",
    "definition": "Cubic yards, used to measure quantities of sand and gravel.",
    "categories": ["Units and Measurements"]
  }, {
    "name": "Direct Use",
    "definition": "Geothermal energy (hot water near the surface of the earth) can be used directly for heating buildings, drying crops, heating water, and other industrial processes.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Disbursement",
    "definition": "After collecting revenue from natural resource extraction, the Office of Natural Resources Revenue (ONRR) distributes that money to different agencies, funds, and local governments for public use. This process is called “disbursement.”",
    "tags": ["disburses"],
    "categories": ["Disbursement"]
  }, {
    "name": "Disbursements to [State Name]",
    "definition": "Funds disbursed to states fall under the jurisdiction of each state, and each state determines how the funds will be used. These disbursements are to the state.  Disbursements directly to the county are listed separately.",
    "categories": ["Disbursement"]
  }, {
    "name": "Dry Natural Gas",
    "definition": "The natural gas that remains after removing the liquefiable hydrocarbon portion from the gas stream. (For example, gas after lease, field, or plant separation.) Any quantities of nonhydrocarbon gases that render the gas unmarketable are also removed.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "DOI",
    "definition": "The U.S. Department of the Interior (DOI) is a Cabinet-level agency. It is responsible for managing America’s natural and cultural resources.",
    "categories": ["Offices and Regulations"]
  }, {
    "name": "Environmental Impact Statement (EIS)",
    "definition": "A document intended to provide decision makers and the public with information about the potential impacts of major federal actions and alternatives to them. Federal agencies prepare an EIS if a proposed federal action is determined to affect the quality of the human environment. This is required by the National Environmental Policy Act (NEPA).",
    "tags": ["Environmental Impact Statement"],
    "categories": ["Offices and Regulations"]
  }, {
    "name": "EITI Standard",
    "definition": "The Extractive Industries Transparency Initiative (EITI) Standard is an international standard for openness around managing revenue from natural resources. Governments disclose how much they receive from extractive companies operating in their country. These companies disclose how much they pay. Governments sign up to implement the EITI Standard and must meet seven requirements. In 2017, the U.S. withdrew from EITI as an Implementing Country. The U.S. remains committed to institutionalizing the EITI principles of transparency and accountability.",
    "tags": ["USEITI"],
    "categories": ["Offices and Regulations"]
  }, {
    "name": "Effective Royalty Rate",
    "definition": "The Office of Inspector General (OIG) created the term and concept of effective royalty rate. The OIG defines the calculation of the effective royalty rate in Report 2021-CR-042 June 2023.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Extractive Industry",
    "definition": "Oil, gas, and mining industries that extract natural resources.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Fair Market Value",
    "definition": "The estimated price for a natural resource lease. It is based on the government’s analysis and the geological resources on the lands or waters.",
    "categories": ["Revenue"]
  }, {
    "name": "Federal Land",
    "definition": "All land and interests in land owned by the United States that are subject to mineral leasing laws, including mineral resources or mineral estates from public domain lands, acquired lands, and the Outer Continental Shelf.",
    "tags": ["federal lands and waters"],
    "categories": ["Land and Ownership"]
  }, {
    "name": "Fiscal Year (FY)",
    "definition": "The federal government’s fiscal year runs from October 1 through September 30. The two annual time periods for reporting data are calendar year and fiscal year.",
    "tags": ["Fiscal Years (FY)"],
    "categories": ["Production and Commodities"]
  }, {
    "name": "Fossil Fuel",
    "definition": "An energy source formed in the Earth’s crust from decayed organic material. Common fossil fuels include oil, gas, and coal.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Fractionation",
    "definition": "The division of ownership among multiple individuals.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Geothermal - Electrical Generation, Kilowatt Hours",
    "definition": "The utilization of geothermal resources to generate electricity for sale. It is measured in electricity (kilowatt hour).",
    "tags": ["Geothermal - electrical generation (kilowatt hours)"],
    "categories": [""]
  }, {
    "name": "Geothermal - Electrical Generation, Other",
    "definition": "The utilization of geothermal resources to generate electricity for sale. It is measured in other.",
    "tags": ["Geothermal - electrical generation (other)"],
    "categories": [""]
  }, {
    "name": "Geothermal - Electrical Generation, Thousands of Pounds",
    "definition": "The utilization of geothermal resources to generate electricity for sale. It is measured in mass (thousands of pounds).",
    "tags": ["Geothermal - electrical generation (thousands of pounds)"],
    "categories": [""]
  }, {
    "name": "Geothermal - Direct Use, Hundreds of Gallons",
    "definition": "The utilization of geothermal for commercial, residential, agricultural, public facilities, or other energy needs other than the commercial production or generation of electricity. It is measured in volume (hundreds of gallons).",
    "tags": ["Geothermal - direct use (hundreds of gallons)"],
    "categories": [""]
  }, {
    "name": "Geothermal - Direct Use, Millions of BTUs",
    "definition": "The utilization of geothermal for commercial, residential, agricultural, public facilities, or other energy needs other than the commercial production or generation of electricity. It is measured in thermal energy (millions of Btu).",
    "tags": ["Geothermal - direct use (millions of btus)"],
    "categories": [""]
  }, {
    "name": "Geothermal - Direct Use, Millions of Gallons",
    "definition": "The utilization of geothermal for commercial, residential, agricultural, public facilities, or other energy needs other than the commercial production or generation of electricity. It is measured in volume (millions of gallons).",
    "tags": ["Geothermal - direct use (millions of gallons)"],
    "categories": [""]
  }, {
    "name": "Geothermal - Sulfur",
    "definition": "A geothermal byproduct. It is commercially recovered from produced geothermal fluids.",
    "categories": [""]
  }, {
    "name": "GOMESA",
    "definition": "The Gulf of Mexico Energy Security Act (GOMESA) of 2006 directs a portion of revenue from Gulf oil and gas royalties to the states of Alabama, Louisiana, Mississippi, and Texas. The act also directs a portion of Gulf revenue be disbursed to the Land and Water Conservation Fund.",
    "tags": ["GOMESA offshore"],
    "categories": ["Offices and Regulations", "Disbursement"]
  }, {
    "name": "Historic Preservation Fund",
    "definition": "Helps preserve U.S. historical and archaeological sites and cultural heritage. Funds are granted to state and tribal historic preservation offices.",
    "tags": ["HPF", "Historic Preservation Fund"],
    "categories": ["Disbursement"]
  }, {
    "name": "Hydraulic Fracturing",
    "definition": "A well development process that involves injecting water under high pressure into a bedrock formation through the well, to increase the size and extent of existing bedrock fractures.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "IMDA",
    "definition": "The Indian Mineral Development Act of 1982. IMDA increased Indian self-governance on extraction.",
    "categories": ["Offices and Regulations"]
  }, {
    "name": "Independent Administrator (IA)",
    "definition": "The EITI International Board requires participating countries to appoint an Independent Administrator. The IA helps apply international standards. The USEITI IA is Deloitte & Touche LLP.",
    "categories": ["Offices and Regulations", "Land and Ownership"]
  }, {
    "name": "Indian Lands",
    "definition": "Lands owned by Native Americans, including tribal lands held in trust by the federal government for a tribe’s use, Indian allotments held in trust by the federal government for individual use, and lands held by Alaska Native corporations.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Inspection Fees",
    "definition": "The Department of the Interior inspects offshore oil and gas drilling rigs at least once a year. Inspection fees help recover some of the costs associated with these inspections.",
    "categories": ["Revenue"]
  }, {
    "name": "Kilowatt Hour (kWh)",
    "definition": "A measure of electrical energy equivalent to a power consumption of 1,000 watts for 1 hour; abbreviated as kWh.",
    "categories": ["Units and Measurements"]
  }, {
    "name": "Land and Water Conservation Fund (LWCF)",
    "definition": "Provides matching grants to states and local governments. These grants are used to buy and develop public outdoor recreation areas across the 50 states.",
    "categories": ["Disbursement"]
  }, {
    "name": "Land Category",
    "definition": "Distinguishes between data collected onshore and offshore within the ONRR datasets.",
    "tags": ["Land Category"],
    "categories": ["Revenue", "Production and Commodities", "Disbursement", "Federal Sales"]
  }, {
    "name": "Land Class",
    "definition": "Distinguishes federal lands and waters from Native American lands within the ONRR datasets.",
    "tags": ["Land Class"],
    "categories": ["Revenue", "Production and Commodities", "Disbursement", "Federal Sales"]
  }, {
    "name": "Land Type",
    "definition": "Combines land class and land category within this website's Query Data tool.",
    "tags": ["Land Type"],
    "categories": ["Revenue", "Production and Commodities", "Disbursement", "Federal Sales"]
  }, {
    "name": "Lease",
    "definition": "Any contract, profit-sharing arrangement, joint venture, or agreement issued or approved by the United States under a mineral leasing law that authorizes exploration for, extraction of, or removal of natural resources for a specified period of time over a given area.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Lease Condensate",
    "definition": "Light liquid hydrocarbons recovered from oil and natural gas wells during production.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Locatable Minerals",
    "definition": "Minerals that may be “located” and obtained by filing a mining claim. Locatable minerals include gold, silver, copper, lead, and many other metallic and nonmetallic minerals.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Location Not Published",
    "definition": "Location data is not published for Native American land. It is only available at the national level. This protects private data and personally identifiable information (PII).",
    "tags": ["Location Not Published"],
    "categories": ["Revenue", "Production and Commodities", "Disbursement"]
  }, {
    "name": "Long Ton",
    "definition": "A long ton (also known as “imperial ton” or “displacement ton”) is 2,240 pounds, compared to a conventional ton (or “short ton”), which is 2,000 pounds.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Margin of Variance",
    "definition": "The percentage difference for each revenue type as part of the reconciliation process.",
    "categories": ["Revenue"]
  }, {
    "name": "Material Variance",
    "definition": "The difference in revenue payments reported by the government verses a company.",
    "categories": ["Revenue"]
  }, {
    "name": "Mcf",
    "definition": "1000 cubic feet, a unit of measure for natural gas.",
    "tags": ["(mcf)"],
    "categories": ["Production and Commodities", "Units and Measurements"]
  }, {
    "name": "Megawatt Capacity (MC) Fee",
    "definition": "A revenue payment for the calculated value of electricity generated on federal lands.",
    "categories": ["Revenue"]
  }, {
    "name": "Megawatt Hours",
    "definition": "One megawatt is equivalent to one million watts. One megawatt hour (abbreviated as Mwh) is equivalent to 1,000 Kilowatt hours.",
    "categories": ["Units and Measurements"]
  }, {
    "name": "Metric Ton",
    "definition": "One metric ton is equal to 2240 pounds. To convert metric tons to tons, multiply by 1.1023. To convert tons to metric tons, multiply by 0.9072.",
    "categories": ["Units and Measurements"]
  }, {
    "name": "Millage Tax",
    "definition": "A millage tax is a property tax based on the assessed value of a property. Millage tax rates are quantified in terms of mills: One mill is worth 1/1000 of a dollar, or $0.001.",
    "categories": ["Revenue"]
  }, {
    "name": "Mill Levy",
    "definition": "A mill levy is calculated by first determining how much revenue each taxing jurisdiction will need for the upcoming year. Then dividing that projection by the total property value within the area.",
    "categories": ["Revenue"]
  }, {
    "name": "Mill Rate",
    "definition": "A mill rate is the amount of tax payable per dollar on the assessed value of a property. Each mill is worth one-tenth of a cent, or $0.001.",
    "categories": ["Revenue"]
  }, {
    "name": "Mineral Acres",
    "definition": "Sometimes the land’s surface owner is different from the owner of the minerals in the ground below. For instance, a state might retain mineral rights when it sells or swaps land.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Mineral Resource Potential",
    "definition": "The likelihood of the occurrence of undiscovered mineral resources in a defined area.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Miscellaneous Receipts",
    "definition": "Found within ONRR's disbursement data. Includes funds for the US Treasury, the Land and Water Conservation Fund, and the Historic Preservation Fund.",
    "categories": ["Disbursement"]
  }, {
    "name": "Mixed Exploratory",
    "definition": "A temporary jurisdictional unit. It is used until production is proven in that location. BLM then creates a permanent unit with allocation schedules that may split that area between federal and other ownership. Payors then resubmit royalties based on the new unit allocations. These royalties are retroactive to the first production.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "MMBtu",
    "definition": "Stands for Million British Thermal Units. One British Thermal Unit (Btu) is the amount of heat required to raise the temperature of one pound of water by one degree Fahrenheit. MMBtu is the globally used energy value measurement.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Multi-Stakeholder Group (MSG)",
    "definition": "A cross-sector body comprised of members and alternates from government, industry, and civil society organizations. The Secretary of the Interior commissioned it to guide and monitor EITI implementation.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Nationwide Federal",
    "definition": "This data includes natural resources produced on United States federal lands and waters. It does not include Native American lands, privately owned lands, or state-owned lands. Federal data is available by location.",
    "tags": ["Federal"],
    "categories": [""]
  }, {
    "name": "Native American",
    "definition": "This data includes natural resources produced on Native American lands only. Location data is withheld from Native American data.",
    "tags": ["Native American lands", "Native American tribes and individuals"],
    "categories": [""]
  }, {
    "name": "Natural Gas Liquids (NGLs)",
    "definition": "Natural gas liquids, such as ethane, propane, and butane, are byproducts of wet natural gas. These liquid hydrocarbons are separated from the gas stream close to the well or at a processing plant.",
    "categories": ["Production and Commodities", "Federal Sales"]
  }, {
    "name": "North American Industry Classification System (NAICS)",
    "definition": "The standard used by federal agencies in classifying business establishments for the purpose of collecting, analyzing, and publishing statistical data related to the U.S. economy.",
    "categories": ["Offices and Regulations"]
  }, {
    "name": "Not Tied to Commodity",
    "definition": "Some revenue do not directly result from the production of a commodity. Revenue types with 'not tied to a commodity' include civil penalties and inspection fees.",
    "tags": ["Not tied to a commodity", "Not Tied to a Commodity"],
    "categories": ["Revenue", "Federal Sales"]
  }, {
    "name": "Not Tied to a Lease",
    "definition": "This term indicates Federal revenue data that did not include either a location or a commodity.",
    "tags": ["Federal - not tied to a lease", "Not Tied to a Lease"],
    "categories": ["Revenue"]
  }, {
    "name": "Not Tied to a Location",
    "definition": "This term indicates Federal onshore revenue data that did not include a location.",
    "tags": ["Federal - not tied to a location", "Not Tied to a Location"],
    "categories": ["Revenue"]
  }, {
    "name": "Not Tied to a Region",
    "definition": "This term indicates Federal offshore revenue data that did not include a region.",
    "tags": ["Federal - not tied to a region", "Not Tied to a Region"],
    "categories": ["Revenue"]
  }, {
    "name": "Offshore",
    "definition": "Submerged lands located farther than three miles off a state’s coastline, or three marine leagues into the Gulf of America off Texas and western Florida.",
    "tags": ["Offshore"],
    "categories": ["Revenue", "Production and Commodities", "Disbursement", "Land and Ownership"]
  }, {
    "name": "ONRR",
    "definition": "The Office of Natural Resources Revenue (ONRR) is part of the U.S. Department of the Interior. It collects, disburses, and verifies federal and Indian energy and other natural resource revenue.",
    "categories": ["Offices and Regulations"]
  }, {
    "name": "Onshore",
    "definition": "Situated or occurring on land.",
    "tags": ["Onshore"],
    "categories": ["Revenue", "Production and Commodities", "Disbursement", "Land and Ownership"]
  }, {
    "name": "Operating Fee",
    "definition": "A fee for a percentage of the anticipated value of wind energy produced on federal waters.",
    "categories": ["Revenue"]
  }, {
    "name": "Other (Disbursements Recipient)",
    "definition": "Certain funds are directed back to the federal agencies that administer these lands to help cover the agencies’ operational costs.  (For example, BLM, U.S. Fish and Wildlife Service, and U.S. Forest Service.) The Ultra-Deepwater Research Program and the Mescal Settlement Agreement also receive $50 million each.",
    "tags": ["Other funds"],
    "categories": ["Disbursement"]
  }, {
    "name": "Other Revenues",
    "definition": "Other revenue includes revenue not tied to a commodity or lease or Native American revenue.",
    "categories": ["Revenue"]
  }, {
    "name": "Outer Continental Shelf",
    "definition": "All submerged lands within the jurisdiction and control of the United States Government as defined in section 2 of the Outer Continental Shelf Lands Act (43 U.S.C. 1331(a)).",
    "categories": ["Land and Ownership"]
  }, {
    "name": "OSMRE",
    "definition": "The Office of Surface Mining Reclamation and Enforcement (OSMRE) is part of the U.S. Department of the Interior. It regulates surface coal mining in the United States and funds the restoration of abandoned coal mines.",
    "categories": ["Offices and Regulations"]
  }, {
    "name": "OST",
    "definition": "The Office of the Special Trustee for American Indians (OST) is part of the Department of the Interior. It is responsible for the stewardship of assets held in trust on behalf of American Indians.",
    "categories": ["Offices and Regulations"]
  }, {
    "name": "Paying Quantities",
    "definition": "Quantities of oil or gas that are sufficient to yield a profit to the lease holder over operating expenses. Paying quantities are still considered even if the undertaking as a whole may result in a loss to the lease holder.",
    "categories": ["Revenue"]
  }, {
    "name": "Permit Fees",
    "definition": "Processing fee for application or permit.",
    "categories": [""]
  }, {
    "name": "Petroleum Products",
    "definition": "Products come from processing crude oil (including lease condensate), natural gas, and other hydrocarbon compounds. These include unfinished oils, liquefied petroleum gases, pentanes plus, aviation gasoline, motor gasoline, naphtha-type jet fuel, kerosene-type jet fuel, kerosene, distillate fuel oil, residual fuel oil, petrochemical feedstocks, special naphthas, lubricants, waxes, petroleum coke, asphalt, road oil, still gas, and miscellaneous products.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Play",
    "definition": "A group of oil and gas fields in the same region formed by the same geological processes.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Private Lands",
    "definition": "Lands owned by citizens or corporations.",
    "tags": ["Private"],
    "categories": ["Land and Ownership"]
  }, {
    "name": "Processing Allowances (PA)",
    "definition": " A deduction from royalty value for the lessee’s actual, reasonable costs of processing a gas stream.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Production",
    "definition": "We use the term “production” as a catch-all term for mining, drilling, energy generation, and other forms of natural resource extraction. There is no distinction between “extraction” and “production” in ONRR or EIA datasets.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Proved Reserves",
    "definition": "The amount of natural resources that is estimated with reasonable certainty to be recoverable and profitable. This analysis uses geological and engineering data. Current economic conditions, operating methods, and government regulations play a role in the determination of a proven reserve. ",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Public Domain Lands",
    "definition": "Lands that have belonged to the federal government since they were obtained from the 13 original colonies, from Native American tribes, or through purchases from other countries. They have not been dedicated to a specific use.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Quality and Gravity Bank Adjustments",
    "definition": "A change in royalty value that accounts for differing qualities of oil in a transportation system used by multiple producers. The quality of the oil is measured by its specific gravity. Oils of differing quality or value have a different specific gravity.",
    "tags": ["quality bank", "gravity bank", "adjustment"],
    "categories": ["Federal Sales"]
  }, {
    "name": "Reclamation",
    "definition": "The process of restoring the land from any effects of extracting natural resources. In most cases, this means a condition equal to that which existed before the land was disturbed. This includes surface contouring, equipment removal, well plugging, and revegetation.",
    "tags": ["reclaim", "reclaiming"],
    "categories": ["Land and Ownership"]
  }, {
    "name": "Reclamation Fund",
    "definition": "Supports the establishment of critical infrastructure projects like dams and power plants.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Rent",
    "definition": "Annual amount due on leases as specified in lease terms. Rental payments are usually due on or before the lease anniversary date.",
    "tags": ["rents", "rental"],
    "categories": ["Revenue"]
  }, {
    "name": "Renewable Energy",
    "definition": "Energy resources that are virtually inexhaustible in duration but limited in the amount of energy that is available per unit of time. These include biomass, hydropower, geothermal, solar, wind, ocean thermal, wave action, and tidal action energy.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Resource Advisory Council (RAC)",
    "definition": "A group of 12 to 15 members with diverse interests in local communities. Communities include ranchers, environmental groups, tribes, state and local government officials, academics, and other public land users.",
    "categories": [""]
  }, {
    "name": "Royalty",
    "definition": "Any part of oil, gas, solid minerals, and geothermal resources or their cash value paid by a lessee to a lessor and/or parties acquiring possession of royalty rights based on a certain percentage of production from the property.",
    "tags": ["royalties"],
    "categories": ["Revenue", "Federal Sales"]
  }, {
    "name": "Royalty Rate",
    "definition": "The rate used to calculate royalty due on federal or Native American production. This rate is always specified in the lease terms.",
    "tags": ["royalties", "royalty"],
    "categories": ["Federal Sales"]
  }, {
    "name": "Royalty Value Less Allowances (RVLA)",
    "definition": "Calculated by subtracting allowances (transportation, processing) from royalty value.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Royalty Value Prior to Allowances (RVPA)",
    "definition": "Calculated by multiplying sales value by royalty rate.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Sales Value",
    "definition": "A reported field on the Form ONRR-2014. Reporters calculate sales value by multiplying the volumes sold by the agreed upon unit price. This calculation is done for each product based on pricing contracts and approved valuation guidelines.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Sales Volume",
    "definition": "A reported field on the Form ONRR-2014. Sales Volume is the quantity and quality of production as measured at the approved royalty measurement point. It is then sold. The Bureau of Land Management (BLM) or Bureau of Safety and Environmental Enforcement (BSEE) approve measuring points.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Sales Year",
    "definition": "Sales transactions that took place in a given federal fiscal or calendar year. It does not include adjusted or corrected transactions for sales from previous years. Because ONRR processes royalty transactions daily, the sales year data constantly changes. For this reason, ONRR publishes sales year datasets with an as-of date. This shows when the information was current.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Sequestration",
    "definition": "Represents statutory spending limits that are withheld from certain government programs. Some program funds are returned in the following fiscal year.",
    "categories": [""]
  }, {
    "name": "Standard Occupation Classification",
    "definition": "A system used by federal statistical agencies to classify workers into occupational categories. This is done to collect, calculate, or disseminate data.",
    "categories": [""]
  }, {
    "name": "State or Local Lands",
    "definition": "Lands owned by state or local governments.",
    "tags": ["State and local governments", "State & local"],
    "categories": ["Land and Ownership"]
  }, {
    "name": "Split Estate",
    "definition": "A land parcel that has surface rights and subsurface rights (such as the rights to develop minerals). Different parties own the land parcel.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Subsurface Rights",
    "definition": "A lease holder’s right to use as much of the land beneath the surface as necessary to operate under the lease.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Subsurface Mining",
    "definition": "Underground mining. It has different and more labor-intensive techniques than surface mining.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Surface Rights",
    "definition": "A leaseholder’s right to use as much of the surface of the land as necessary to operate under the lease.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "Tax Expenditures",
    "definition": "Revenue losses attributed to provisions of federal tax laws. These tax laws allow a special exclusion, exemption, or deduction from gross income. They also may provide a special credit, a preferential rate of tax, or a deferral of tax liability.",
    "categories": ["Revenue"]
  }, {
    "name": "Transportation Allowances (TA)",
    "definition": "Deductions in determining royalty value for the reasonable, actual costs incurred by the lessee, or a deduction accepted or established by ONRR for moving coal from the mine or lease to a remote point of sale or delivery, or moving oil or gas, gas, or geothermal byproducts to a point of sale or delivery off of the lease, unit area, or communitized area.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Tickets/Pounds",
    "definition": "Some minerals, such as quartz crystal, are sold in small quantities in gift shops and tourist attractions. In some cases, tourists can buy admission tickets to dig for their own minerals. ONRR may collect royalties on the admission tickets, not on the weight of minerals collected. Some minerals are sold by quality rather than by weight. The unit “tickets/pounds” shows quantities measured in both tickets sold and weight sold combined.",
    "tags": ["(tickets/pounds)"],
    "categories": ["Units and Measurements"]
  }, {
    "name": "Ton",
    "definition": "In the U.S., one ton is 2,000 pounds. In some countries this is referred to as a short ton.",
    "tags": ["(tons)"],
    "categories": ["Units and Measurements"]
  }, {
    "name": "Tons - Equivalent",
    "definition": "Purge liquor is recovered from solution mining of Trona ore. The volume is first measured in liquid units. It is then converted to an equivalent tonnage when recovering royalty-bearing minerals from solution Trona mining.",
    "tags": ["(tons - equivalent)", "equivalent tons"],
    "categories": ["Units and Measurements"]
  }, {
    "name": "Trust Land",
    "definition": "Land for which the federal government holds title to the land but holds it for the beneficial interests of an Native American tribe or an individual of Native American or Alaska Native ancestry.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Unit Value",
    "definition": "The per-unit price that you apply to the royalty-bearing volume.",
    "categories": ["Federal Sales"]
  }, {
    "name": "Unorganized Land",
    "definition": "In Alaska, over half of land is not contained in any of its 19 organized boroughs. This land (collectively called the Unorganized Borough) is divided into 10 census areas for statistical purposes.",
    "categories": ["Land and Ownership"]
  }, {
    "name": "U.S. Treasury",
    "definition": "Funds disbursed to the Treasury go to the General Fund. The General Fund is the federal government’s basic operating fund. It pays for roughly two-thirds of all federal expenditures, including the U.S. military, national parks, and schools.",
    "tags": ["US Treasury"],
    "categories": [""]
  }, {
    "name": "Wet Gas",
    "definition": "Natural gas that hasn’t been treated to remove liquid hydrocarbons or other nonhydrocarbons that make the gas unmarketable.",
    "categories": ["Production and Commodities"]
  }, {
    "name": "Withheld",
    "definition": "We refer to data as “withheld” when publishing that data could violate federal laws and regulations. We withhold data if it can be used to identify individuals or if the Trade Secrets Act protects the data. In the latter case, data is often withheld when only one company produces a specific commodity within a particular region. We withhold all location data for Native American production, revenue, and disbursements.",
    "categories": ["Production and Commodities"]
  }]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /*
       This is currently just a place for defining the terms. The object is currently queried by the DefaultLayout component that then hydrates the GlossaryContext.
       The GlossaryTerm component then calls an action on the GlossaryContext to retrieve the definition for its tooltip display.
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      